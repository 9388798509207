import { Component } from '@angular/core';
import { HoraireTrajet } from './models/horaire-trajet.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  aller!: HoraireTrajet;
  retour!: HoraireTrajet;

  ngOnInit() {

    this.aller = {
      horaire_debut: "063000",
      periode_in_hours: 3,  
      button_label: "06h30 - 09h30",
      title: "Chantilly vers Paris",
      id_from: 87276113,
      id_to: 87271007,
      paramIDF: "MonitoringRef=STIF%3AStopPoint%3AQ%3A411427%3A&LineRef=STIF%3ALine%3A%3AC02372%3A",
    }

    this.retour = {
      horaire_debut: "163000",
      periode_in_hours: 3,  
      button_label: "16h30 - 19h30",
      title: "Paris vers Chantilly",
      id_from: 87271007,
      id_to: 87276113,
      paramIDF: "MonitoringRef=STIF%3AStopPoint%3AQ%3A41071%3A&LineRef=STIF%3ALine%3A%3AC02372%3A",
    }
  }
}
