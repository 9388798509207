<mat-card class="card-horaire">
  <mat-card-header>
    <mat-card-title>{{horaireTrajet.title}}</mat-card-title>
    <mat-card-subtitle style="font-size: 12px; margin-bottom:0;">
     Horaires à partir du {{dateSubtitle}} <span style="font-style:italic;"> (màj. {{dateUpdate}})</span>
</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="train">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"> {{element.train}}</td>
      </ng-container>
      <ng-container matColumnDef="depart">
        <th mat-header-cell *matHeaderCellDef> Départ </th>
        <td mat-cell *matCellDef="let element">
           <span>{{element.heure_depart | number:'2.0'}}h{{element.minute_depart | number:'2.0'}}</span>
           <span *ngIf="element.heure_depart_delay != undefined && (element.heure_depart != element.heure_depart_delay || element.minute_depart != element.minute_depart_delay)" class="delay"><br>{{element.heure_depart_delay | number:'2.0'}}h{{element.minute_depart_delay | number:'2.0'}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="arrivee">
        <th mat-header-cell *matHeaderCellDef> Arrivée </th>
        <td mat-cell *matCellDef="let element">
          <span>{{element.heure_arrivee | number:'2.0'}}h{{element.minute_arrivee | number:'2.0'}}</span>
          <span *ngIf="element.heure_arrivee_delay != undefined && (element.heure_arrivee != element.heure_arrivee_delay || element.minute_arrivee != element.minute_arrivee_delay)" class="delay"><br>{{element.heure_arrivee_delay | number:'2.0'}}h{{element.minute_arrivee_delay | number:'2.0'}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="duree">
        <th mat-header-cell *matHeaderCellDef> Durée </th>
        <td mat-cell *matCellDef="let element"> {{element.duree}} min.</td>
      </ng-container>
      <ng-container matColumnDef="quai">
        <th mat-header-cell *matHeaderCellDef> Quai </th>
        <td mat-cell *matCellDef="let element">{{element.quai_depart}}
          <span *ngIf="element.quai_arrivee != undefined"> → {{element.quai_arrivee}}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row [class.delay] = "row.cancel" (click)="openSnackBar(row.alert)"  *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-button (click)="updateJourney(2)">{{horaireTrajet.button_label}}</button>
    <button mat-button (click)="updateJourney(1)">MAINTENANT</button>
    <mat-form-field style="display: none">
      <input [mtxDatetimepicker]="datetimePicker" [(ngModel)]="datePicked" name="datePicked" matInput required>
    </mat-form-field>
    <mtx-datetimepicker #datetimePicker
                          [type]="type"
                          [mode]="mode"
                          [multiYearSelector]="multiYearSelector"
                          [startView]="startView"
                          [twelvehour]="twelvehour"
                          [timeInterval]="timeInterval"
                          [touchUi]="touchUi"
                          [timeInput]="timeInput"
                          (selectedChanged)="updateJourney($event)"></mtx-datetimepicker>
    <mtx-datetimepicker-toggle [for]="datetimePicker" matSuffix></mtx-datetimepicker-toggle>
  </mat-card-actions>
</mat-card>
