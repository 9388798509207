import { Component, Input, OnInit, getPlatform } from '@angular/core';
import { HoraireTrajet } from '../models/horaire-trajet.model';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import {formatDate} from '@angular/common';
import { UntypedFormControl } from '@angular/forms';
import {
  MtxCalendarView,
  MtxDatetimepickerMode,
  MtxDatetimepickerType,
} from '@ng-matero/extensions/datetimepicker';
import { MTX_DATETIME_FORMATS } from '@ng-matero/extensions/core';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({ 
  selector: 'app-horaire-trajet',
  templateUrl: './horaire-trajet.component.html',
  styleUrls: ['./horaire-trajet.component.scss'],
  providers: [
    {
      provide: MTX_DATETIME_FORMATS,
      useValue: {
        parse: {
          dateInput: 'YYYY-MM-DD',
          monthInput: 'MMMM',
          yearInput: 'YYYY',
          timeInput: 'HH:mm',
          datetimeInput: 'YYYY-MM-DD HH:mm',
        },
        display: {
          dateInput: 'YYYY-MM-DD',
          monthInput: 'MMMM',
          yearInput: 'YYYY',
          timeInput: 'HH:mm',
          datetimeInput: 'YYYY-MM-DD HH:mm',
          monthYearLabel: 'YYYY MMMM',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
          popupHeaderDateLabel: 'MMM DD, ddd',
        },
      },
    },
  ],
})
export class HoraireTrajetComponent implements OnInit {
  @Input() horaireTrajet!: HoraireTrajet
  public dataSource:any;
  dateSubtitle:string = "";
  dateUpdate:string = "";
  displayedColumns: string[] = ['train', 'depart', 'arrivee', 'duree', 'quai'];
  type: MtxDatetimepickerType = 'datetime';
  mode: MtxDatetimepickerMode = 'auto';
  startView: MtxCalendarView = 'month';
  multiYearSelector = false;
  touchUi = true;
  twelvehour = false;
  timeInterval = 5;
  timeInput = true;
  datePicked:any;
  

  datetime = new UntypedFormControl();
  
  constructor(private _httpClient: HttpClient, private _snackBar: MatSnackBar) {
    this.dataSource = [];
   }

  ngOnInit(): void {
    if((new Date()).getHours() >= parseInt(this.horaireTrajet.horaire_debut.substr(0,2))+this.horaireTrajet.periode_in_hours+1) {
      this.horaireTrajet.isToday = false;
    } else {
      this.horaireTrajet.isToday = true;
    }

    this.updateJourney(1);
  }

  openSnackBar(message: string) {
    if (message != undefined && message != "") {
      this._snackBar.open(message, "Fermer", {
        duration: 3000
      });
    }
  }

  updateJourney(type:any):void {
    switch (type) {
      case 1: //MAINTENANT
        this.getJourney(formatDate(new Date(), 'HHmmss', 'fr'), new Date())
        break;
      case 2: //PERIODE
          let jourDebut = new Date()
          if (!this.horaireTrajet.isToday) {
            jourDebut = new Date(new Date().getTime()+(1*24*60*60*1000))
          } 
        this.getJourney(this.horaireTrajet.horaire_debut, jourDebut);
        break;
      default: //FORM
        this.getJourney(formatDate(type, 'HHmmss', 'fr'), type);
        break;
    }
  }

  getJourney(horaire_debut:any, jourDebut:Date): void {

    let jour_debut = formatDate(jourDebut, 'yyyyMMdd', 'fr')

    let getTrajetUrl="https://api.sncf.com:443/v1/coverage/sncf/journeys?from=stop_point:SNCF:" + this.horaireTrajet.id_from + ":Train&to=stop_point:SNCF:" + this.horaireTrajet.id_to + ":Train&datetime="+ jour_debut + "T" + horaire_debut + "&timeframe_duration=" + this.horaireTrajet.periode_in_hours*3600 + "&key=2cd1aa0f-f559-423d-a253-3bb5a8de217a"
    

    this._httpClient.get(getTrajetUrl)
      .subscribe((journeyResponse:any) => {
        const journeyList = journeyResponse.journeys;
        let dataSourceRefresh:any = []
        for (let index = 0; index < journeyList.length; index++) {
          const journey = journeyList[index];
          if(journey['nb_transfers'] == 0) {
            let idxSection = 0
            if (journey['sections'].length == 3) {
              idxSection = 1 //cas quand RER
            } 
            let departure_date_time = this.formatDateComplete(journey['sections'][idxSection]['departure_date_time'], jourDebut)
            let arrival_date_time = this.formatDateComplete(journey['sections'][idxSection]['arrival_date_time'], jourDebut)
            //if(arrival_date_time < new Date()) continue
            dataSourceRefresh.push(
              {
                "heure_depart": departure_date_time.getHours(),
                "minute_depart": departure_date_time.getMinutes(),
                "heure_arrivee": arrival_date_time.getHours(),
                "minute_arrivee": arrival_date_time.getMinutes(),
                "duree": Math.round(journey['sections'][idxSection]['duration']/60),
                "train": journey['sections'][idxSection]['display_informations']['label'],
                "numTrain": journey['sections'][idxSection]['display_informations']['trip_short_name'],
              }
            )
          }
          
        }
		    //this.dataSource = dataSourceRefresh
        dataSourceRefresh = this.researchDelay(dataSourceRefresh, journeyResponse.disruptions)
        this.getPlatformSNCF(dataSourceRefresh, "depart", horaire_debut, jourDebut);

      })
  }

  /* getPlatformRATP(dataSourceRefresh:any): void {
    let getPlatformUrl = "https://prim.iledefrance-mobilites.fr/marketplace/stop-monitoring?" + this.horaireTrajet.paramIDF
    let header = new HttpHeaders({'Content-Type': 'application/json', 'apikey': 'uEGARSYONLYicA44OpCIB9o770aqArKb', 'Access-Control-Allow-Origin':'*',});

	  const journeyList = []
	  for (let index = 0; index < journeyList.length; index++) {
		const journeyIDF = journeyList[index];
		for (let index = 0; index < dataSourceRefresh.length; index++) {
		  const journeySNCF = dataSourceRefresh[index];
		  if (journeyIDF["MonitoredVehicleJourney"]["TrainNumbers"]["TrainNumberRef"][0]["value"] == journeySNCF["numTrain"] && journeyIDF["MonitoredVehicleJourney"]["MonitoredCall"]["ArrivalPlatformName"] != undefined) {
        journeySNCF["quai"] = journeyIDF["MonitoredVehicleJourney"]["MonitoredCall"]["ArrivalPlatformName"]["value"];
        console.log(journeySNCF)
		  }
		
		}
	  }
	  this.dataSource = dataSourceRefresh
  }
   */

  researchDelay(dataSourceRefresh:any, disruptions:any): any {
    for (let index = 0; index < disruptions.length; index++) {
      const disruption = disruptions[index];
      for(let impactedObject of disruption.impacted_objects) {
        for(let journey of dataSourceRefresh) {
          if(journey.numTrain == impactedObject.pt_object.trip.name) {
if (disruption.messages !== undefined) {
journey["alert"] = "[INFO " + journey["train"] + "] " + disruption.messages[0].text;
}
            if(disruption.severity.effect == "NO_SERVICE") {
               journey["cancel"] = true;
               continue;
            }
            for(let impactedStop of impactedObject.impacted_stops) {
              if((impactedStop.stop_point.id).includes(this.horaireTrajet.id_from)) {
if(impactedStop.departure_status == "deleted") {
journey["cancel"] = true;
continue;
}              

  journey["heure_depart"] = impactedStop.amended_departure_time.substr(0,2)
                journey["minute_depart"] = impactedStop.amended_departure_time.substr(2,2)
                journey["heure_depart_delay"] = impactedStop.base_departure_time.substr(0,2)
                journey["minute_depart_delay"] = impactedStop.base_departure_time.substr(2,2)
              }
              if((impactedStop.stop_point.id).includes(this.horaireTrajet.id_to )) {
if(impactedStop.arrival_status == "deleted") {
journey["cancel"] = true; 
continue; 
}               
 journey["heure_arrivee"] = impactedStop.amended_arrival_time.substr(0,2)
                journey["minute_arrivee"] = impactedStop.amended_arrival_time.substr(2,2)
                journey["heure_arrivee_delay"] = impactedStop.base_arrival_time.substr(0,2)
                journey["minute_arrivee_delay"] = impactedStop.base_arrival_time.substr(2,2)
              }
            };
            //journey["alert"] = "[INFO " + journey['train'] + "] " + disruption.messages[0].text;
          }
        };
      }; 
    }
    return dataSourceRefresh
  }

  getPlatformSNCF(dataSourceRefresh:any, type:string, horaire_debut:any, jourDebut:Date): any {
    let getPlatformUrl = "/API/PIV/Arrivals/00" + this.horaireTrajet.id_to
    if (type == "depart") {
      getPlatformUrl = "/API/PIV/Departures/00" + this.horaireTrajet.id_from
    }
    let header = new HttpHeaders({'Content-Type': 'application/json', 'ocp-apim-subscription-key': '3fd14f8500c64ed69e7cb05f2c3a4477', 'Access-Control-Allow-Origin':'*',});

    this._httpClient.get(getPlatformUrl, { headers: header })
    .subscribe((journeyResponse:any) => {
      //const journeyList = journeyResponse.Siri.ServiceDelivery.StopMonitoringDelivery.MonitoredStopVisit;
      for (let index = 0; index < journeyResponse.length; index++) {
        const journeyPlatform = journeyResponse[index];
        for (let index = 0; index < dataSourceRefresh.length; index++) {
          const journeySNCF = dataSourceRefresh[index];
          if (journeyPlatform["trainNumber"] == journeySNCF["numTrain"] && journeyPlatform["platform"] != undefined) {
            journeySNCF["quai_" + type] = journeyPlatform["platform"]["track"];
            if(journeyPlatform.scheduledTime != journeyPlatform.actualTime) {
              journeySNCF["heure_" + type] = new Date(journeyPlatform.actualTime).getHours()
              journeySNCF["minute_" + type] = new Date(journeyPlatform.actualTime).getMinutes()
              journeySNCF["heure_" + type + "_delay"] = new Date(journeyPlatform.scheduledTime).getHours()
              journeySNCF["minute_" + type + "_delay"] = new Date(journeyPlatform.scheduledTime).getMinutes()
            }
            console.log(journeySNCF)
          }
        }
      }
      if (type == "depart") {
        this.getPlatformSNCF(dataSourceRefresh, "arrivee", horaire_debut, jourDebut);
      } else {
        this.dataSource = dataSourceRefresh
        this.dateSubtitle = formatDate(jourDebut, 'dd/MM/yy','fr') + " " + horaire_debut.substr(0,2) + ":" + horaire_debut.substr(2,2)
        this.dateUpdate = formatDate(new Date(), 'dd/MM HH:mm','fr')

      }
    })
  }

  formatDateComplete(d:string, jourDebut:Date):Date {
    if(jourDebut.getTimezoneOffset() == -60) {
      return new Date(d[0]+d[1]+d[2]+d[3]+"-"+d[4]+d[5]+"-"+d[6]+d[7]+d[8]+d[9]+d[10]+":"+d[11]+d[12]+":"+d[13]+d[14]+".000+01:00")
    } else {
      return new Date(d[0]+d[1]+d[2]+d[3]+"-"+d[4]+d[5]+"-"+d[6]+d[7]+d[8]+d[9]+d[10]+":"+d[11]+d[12]+":"+d[13]+d[14]+".000+02:00")
    }
  }

}
